function saveToBucket(file, filename, userFirebaseId, firebase, fn) {
  // Timestamp
  var ts = Date.now();
  // Create a root reference
  var storageRef = firebase.storage().ref();
  // Upload file and metadata to the object
  var uploadTask = storageRef.child(userFirebaseId + "/" + ts + "/" + filename).put(file);

  console.log('Upload for ' + filename + ' has started');

  // Listen for state changes, errors, and completion of the upload.
  uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
    function(snapshot) {
      // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
      //var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      //console.log('Upload is ' + progress + '% done');
      // switch (snapshot.state) {
      //   case firebase.storage.TaskState.PAUSED: // or 'paused'
      //     //console.log('Upload is paused');
      //     break;
      //   case firebase.storage.TaskState.RUNNING: // or 'running'
      //     //console.log('Upload is running');
      //     break;
      // }
    }, function(error) {
      // A full list of error codes is available at
      // https://firebase.google.com/docs/storage/web/handle-errors
      switch (error.code) {
        case 'storage/unauthorized':
          console.error("User doesn't have permission to access the object");
          break;

        case 'storage/canceled':
          console.error("User canceled the upload");
          break;

        case 'storage/unknown':
          console.error("Unknown error occurred, inspect error.serverResponse");
          break;
        default:
          console.error("error: " +error.code);
      }
      fn({
        "errorText": "Error saving to bucket", 
        "error":error
      });
    }, function() {
      // Upload completed successfully, now we can get the download URL
      uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
        //console.log('File available at', downloadURL);
        fn({
          "downloadURL": downloadURL
        });
      });
    }
  )
}

export { saveToBucket };