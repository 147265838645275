import {html, render} from 'lit-html';
import { saveToBucket } from "./persist.js"
import { getMelGanOutput, getModels } from "./melGanServer.js"
import { makeWaveSurferObject } from "./waves.js"
import { signInWithGoogleOauth, signOutWithGoogleOauth } from "./auth.js"
import { showState } from "./state.js"
import { Spinner } from 'spin.js';
import Noty from 'noty';

Noty.overrideDefaults({
    theme       : 'semanticui',
    timeout     : '4000',
    closeWith   : ['click'],
    layout      : 'bottomRight'
});

const dragDrop = require('drag-drop')
var firebase = require('firebase/app');
require('firebase/auth');
require('firebase/storage');

var app = firebase.initializeApp({
    apiKey: "AIzaSyC1Z_U9-j2840IAM8007zyP6E9m2e2_Qw4",     // Auth / General Use
    authDomain: "creator-268715.firebaseapp.com",          // Auth with popup/redirect
    storageBucket: "creator-268715.appspot.com"            // Storage
});

var userFirebaseId = null;
var currentModel = null;
var currOrigFile = null;
var currTransFile = null;
var currOrigFileUrl = "";
var currTransFileUrl = "";

var originalWavesurfer = makeWaveSurferObject( document.getElementById('original-wave') );
var transformedWavesurfer = makeWaveSurferObject( document.getElementById('transformed-wave') );

var spinnerOpts = {
    lines: 13, // The number of lines to draw
    length: 38, // The length of each line
    width: 17, // The line thickness
    radius: 45, // The radius of the inner circle
    scale: .20, // Scales overall size of the spinner
    corners: 1, // Corner roundness (0..1)
    // CSS color or array of colors
    color: 'linear-gradient(150deg, rgba(0,255,225,1) 0%, rgba(255,0,162,1) 150%)',
    fadeColor: 'transparent', // CSS color or array of colors
    speed: 1, // Rounds per second
    rotate: 0, // The rotation offset
    animation: 'spinner-line-fade-quick', // The CSS animation name for the lines
    direction: 1, // 1: clockwise, -1: counterclockwise
    zIndex: 2e9, // The z-index (defaults to 2000000000)
    className: 'spinner', // The CSS class to assign to the spinner
    top: '50%', // Top position relative to parent
    left: '30%', // Left position relative to parent
    shadow: '0 0 1px transparent', // Box-shadow for the lines
    position: 'absolute' // Element positioning
};
var spinner = new Spinner(spinnerOpts);

// get models from server
async function models() {
    var availableModels = await getModels();
    currentModel = availableModels[0];
    makeModelButtons(availableModels);
    document.getElementById(currentModel + '-' + 'model-btn').style.backgroundColor = "turquoise";
}
models()

//////////////////////////////////////////////////////
// LISTENERS
//////////////////////////////////////////////////////
dragDrop(document.getElementById('dropzone'), {
    onDrop: (files, pos, fileList, directories) => {
        showState(document, "dragend");
        var file = files[0];
        initiateUpload(file);
    },
    onDragEnter: () => {
        showState(document, "dragover");
    },
    onDragOver: () => {
        showState(document, "dragover");        
    },
    onDragLeave: () => {
        showState(document, "dragend");
    }
  })

function initiateUpload(file) {
    showState(document, "soundboxesVisible");
    // one at a time for now
    if ( file.type == "audio/wav" || file.type == "audio/mp3" ) {
        showState(document, "loadingNewFile");
        showState(document, "loadingNewTransform", currentModel);
        loadOriginalFile(file);
        transformedWavesurfer.empty();
        
        var spinnerTarget = document.getElementById('wavesurfer-copy-trans');
        spinner.spin(spinnerTarget);
        
        transformSound(file, currentModel);
    } else {
        var errorText = "Unsupported file format. Only wav and mp3 supported at this time."
        console.error(errorText);
        new Noty({
            type: 'error',
            text: errorText,
        }).show();
    }
}

document.getElementById('sign-in').onclick = function() {
    signInWithGoogleOauth(firebase, function(error) {
        if ( error ) {
            new Noty({
                type: 'error',
                text: JSON.stringify(error),
            }).show();
        }
    });
};

document.getElementById('sign-out').onclick = function() {
    signOutWithGoogleOauth(firebase, function() {
        showState(document, "userNotLoggedIn");
    });
};

document.getElementById('play-orig').onclick = function() {
    originalWavesurfer.playPause();
}
document.getElementById('replay-orig').onclick = function() {
    originalWavesurfer.seekTo(0);
}

document.getElementById('play-trans').onclick = function() {
    transformedWavesurfer.playPause();
}
document.getElementById('replay-trans').onclick = function() {
    transformedWavesurfer.seekTo(0);
}

document.getElementById('download-orig').onclick = function() {
    downloadFile(currOrigFile, currOrigFile.name);
}

document.getElementById('download-trans').onclick = function() {
    downloadFile(currTransFile, currentModel + "_" + currOrigFile.name);
}

document.getElementById('copy-link-orig').onclick = function() {
    copyToClipboard(currOrigFileUrl, this);
}
document.getElementById('copy-link-trans').onclick = function() {
    copyToClipboard(currTransFileUrl, this);
}
document.getElementById('copy-link-trans').onpointerleave = function() {
    this.title = "Copy link to clipboard";
}
document.getElementById('copy-link-orig').onpointerleave = function() {
    this.title = "Copy link to clipboard";
}

document.getElementById('upload-orig').onclick = function() {
    document.getElementById('file-input').click();
}

document.getElementById('initial-copy').onclick = function() {
    document.getElementById('file-input').click();
}



document.getElementById('file-input').onchange = function () {
    if ( this.files.length > 0 ) {
        // one at a time for now
        var file = this.files[0];
        initiateUpload(file);
    }
}

function onModelSelect(_this) {
    var previousButton = document.getElementById(currentModel + '-' + 'model-btn');
    var clickedButton = _this.target;
    
    if ( previousButton != clickedButton ) {
        currentModel = _this.target.value;
        previousButton.style.backgroundColor = "initial";
        clickedButton.style.backgroundColor = "turquoise";

        if ( currOrigFile ) {
            transformedWavesurfer.empty();
            showState(document, "loadingNewTransform", currentModel);
            
            var spinnerTarget = document.getElementById('wavesurfer-copy-trans');
            spinner.spin(spinnerTarget);
            
            transformSound(currOrigFile, currentModel);
        } else {
            var originalCopy = document.getElementById('wavesurfer-copy-orig');
            originalCopy.style.fontSize = '1.25em';
            setTimeout(function(){
                originalCopy.style.fontSize = '1em';
            },200 );
        }
    } else {
        // don't need to re-translate what we have already translated. This is deterministic brah.
    }
}

firebase.auth().onAuthStateChanged(function(user) {
    if (user) {
        // User is signed in.
        userFirebaseId = user.uid;
        showState(document, "userLoggedIn");
    } else {
        // No user is signed in.
        showState(document, "userNotLoggedIn");
    }
});

//////////////////////////////////////////////////////
// LOCAL FUNCTIONS
//////////////////////////////////////////////////////
function copyToClipboard(s,el) {
    //el.title="Link copied to clipboard!";
    var copyText = document.getElementById("share-url-orig");
    copyText.value = s;
    copyText.select();
    document.execCommand("copy");

    new Noty({
        type: 'success',
        text: 'Link copied to clipboard!',
    }).show();
}

function downloadFile(file, name) {
    const a = document.createElement('a')
    var url = window.URL.createObjectURL(file);
    a.href = url;
    a.download = name;
    a.click();
}

function loadOriginalFile(file) {
    originalWavesurfer.loadBlob(file);
    showState(document, "showOriginalWaveform");
    currOrigFileUrl = "";
    saveToBucket(file, 'orig-' + file.name, userFirebaseId, firebase, function(r){
        if ( r.error ) {
            new Noty({
                type: 'error',
                text: JSON.stringify(error),
            }).show();
        } else {
            currOrigFileUrl = r.downloadURL;
        }
    });
    currOrigFile = file;
}

async function transformSound(file, model) {
    var output = await getMelGanOutput(file, model);
    showState(document, "showTransformedWaveform");
    if ( output instanceof Blob ) {
        currTransFile = output;
        transformedWavesurfer.loadBlob(currTransFile);
        
        // save waveforms to GCP
        currTransFileUrl = "";
        saveToBucket(currTransFile, 
                     currentModel + "-" + file.name, 
                     userFirebaseId, 
                     firebase, 
                     function(r){
                        if ( r.error ) {
                            new Noty({
                                type: 'error',
                                text: JSON.stringify(error),
                            }).show();
                        } else {
                            currTransFileUrl = r.downloadURL;
                        }
                     }
        );
    } else {
        new Noty({
            type: 'error',
            text: "Error translating file.<br/> Please try another file, or make the clip shorter than 30 secs. <br> " + output.caughtError.stack,
        }).show();
        originalWavesurfer.empty();
        showState(document, "initial");
    }
    spinner.stop();
}

async function makeModelButtons(availableModels) {
    var modelsContainer = document.getElementById('models-container');
    var i = 0;
    const radioModelTemplate = (modelNames) => html`
        ${modelNames.map(
            modelName =>  {
                i++;
                var side;
                var btnClass = "model-btn";
                if ( i==1 ) {
                    btnClass += "-left"
                } else if ( i==modelNames.length ) {
                    btnClass += "-right"
                } else {
                    btnClass = ""
                }
                
                return html`
                    <button class="model-btn ${btnClass}" 
                            id="${modelName}-model-btn" 
                            @click=${onModelSelect}" 
                            value="${modelName}">
                        <label for="${modelName}-model">${modelName}</label>
                    </button>
                `
            }
        )}
    `;
    render(radioModelTemplate(availableModels), modelsContainer);
}