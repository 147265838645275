import WaveSurfer from 'wavesurfer.js';

function makeWaveSurferObject( container ) {
    var ws = WaveSurfer.create({
        container: container,
        waveColor: 'blue',
        progressColor: 'teal',
        backgroundColor: 'whitesmoke',
        height: 150
    });
    
    return ws;
}

export { makeWaveSurferObject }