import { showState } from "./state.js"

function signInWithGoogleOauth(firebase, fn) {
    // Google Oauth
    // https://firebase.google.com/docs/auth/web/google-signin
    var provider = new firebase.auth.GoogleAuthProvider();
    
    firebase.auth().signInWithPopup(provider).then(function(result) {
        // This is handled with the state change. Keeping the below in while developing.

        // This gives you a Google Access Token. You can use it to access the Google API.
        // var token = result.credential.accessToken;
        // The signed-in user info.
        // var user = result.user;
        console.log("result: " + JSON.stringify(result));
        fn();
    }).catch(function(error) {
        console.error("error: " + JSON.stringify(error));
        fn(error);
    });
}

function signOutWithGoogleOauth(firebase, fn) {
    firebase.auth().signOut().then(function() {
        fn();
    })
}

export { signInWithGoogleOauth, signOutWithGoogleOauth }