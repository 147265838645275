// TODO: convert to CONST / 
function showState(document, state, currentModel) {
    switch(state) {
        case "userNotLoggedIn":
            document.getElementById('sign-in').style.display = "initial";
            document.getElementById('sign-out').style.display = "none";
            document.getElementById('content').style.display = "none";
            break;
        case "userLoggedIn":
            document.getElementById('sign-in').style.display = "none";
            document.getElementById('sign-out').style.display = "initial";
            document.getElementById('content').style.display = "initial";
            break;
        case "soundboxesVisible":
            document.getElementById('initial-copy-content').style.visibility = "hidden";
            document.getElementById('transformation-content').style.visibility = "visible";
            break;
        case "initial":
            document.getElementById('sign-in').style.display = "none";
            document.getElementById('sign-out').style.display = "initial";
            document.getElementById('content').style.display = "initial";
            document.getElementById('download-trans').style = "";
            document.getElementById('copy-link-trans').style = "";
            document.getElementById('download-orig').style = "";
            document.getElementById('copy-link-orig').style = "";
            document.getElementById('wavesurfer-copy-orig').style = "";
            document.getElementById('wavesurfer-copy-orig').innerHTML = "Drop in a soundfile to hear it Translated.";
            break;
        case "showOriginalWaveform":
            document.getElementById('wavesurfer-copy-orig').style.display = "none";
            document.getElementById('download-orig').style.display = "initial";
            document.getElementById('copy-link-orig').style.display = "initial";
            break;
        case "showTransformedWaveform":
            document.getElementById('wavesurfer-copy-trans').style.display = "none";
            document.getElementById('download-trans').style.display = "initial";
            document.getElementById('copy-link-trans').style.display = "initial";
            break;
        case "loadingNewFile":
            document.getElementById('wavesurfer-copy-orig').innerHTML = "Loading File";
            document.getElementById('wavesurfer-copy-trans').innerHTML = "Waiting to apply Sonic<b>Transform</b>™";
            break;
        case "loadingNewTransform":
            document.getElementById('wavesurfer-copy-trans').style = "";
            document.getElementById('wavesurfer-copy-trans').innerHTML = "Translating to " + currentModel;
            document.getElementById('download-trans').style = "";
            document.getElementById('copy-link-trans').style = "";
            break;
        case "dragover":
            document.getElementById('dropbox-area').style.opacity = "80%";
            break;
        case "dragend":
            document.getElementById('dropbox-area').style.opacity = "0%";
            break;
        default:
            //nothing right now            
    }
}

export { showState }