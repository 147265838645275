const url = "https://us-central1-timbre-transfer.cloudfunctions.net/proxy";
const secret = "tYFHDoYxcp";

async function getMelGanOutput(file, model) {
    const formData = new FormData();
    formData.append('model', model);
    formData.append('file', file);
    
    var data = await makePostReq(formData);
    if ( data instanceof ArrayBuffer) {
        var blob = new Blob([data],{type : 'audio/wav'})
        return blob;
    } else if ( data.error ) {
        return data.error
    }
}

async function getModels() {
    return makeGetReq(url + "/models")
}

async function makePostReq(formData) {
    formData.append('secret', secret);
    const response = await fetch(url,{
        body: formData,
        method: "post"
    }).catch((error) => {
        return { "caughtError": error };
    });
    if (!response.ok){
        var error = { "error": response }
        if ( response.status ) {
            console.error("response.statusText: " + response.statusText)
            console.error("response.status: " + response.status)
        } else {
            console.error("response: " + response.caughtError)
        }
        return error; 
    } else {
        const data = await response.arrayBuffer()
        return data;
    }
}

async function makeGetReq(url) {
    const response = await fetch(url,{
        method: "get"
    });
    if (response.ok){
        const data = await response.json()
        return data;
    }
}

export {getMelGanOutput,getModels};